import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    h1: {
      fontSize: '5rem',
      fontWeight: '600',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: '400',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '400',
    },
    body: {
      fontSize: '1rem',
      fontWeight: '400',
    }
  },
  palette: {
    primary: {
      main: '#000000',
    },
    background: {
      default: '#FFFFFF',
    },
  },
});

export default theme;