import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';

function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default Layout;